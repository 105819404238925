.SearchBar {
  &__wrapper {
    display: flex;
    align-items: center;

    &--narrow {
      padding-right: 16px;
    }
  }

  &__search-input {
    width: 100%;
    padding-left: 0.6em;
  }

  &__search-wrapper {
    position: relative;
    flex: 1;
  }

  &__reset-button {
    position: absolute;
    right: 0;
    top: 0;
    padding-right: 0.8em;
    cursor: pointer;
    opacity: 0.7;

    &:hover {
      opacity: 1;
    }

    &--inactive {
      &,
      &:hover {
        opacity: 0.1;
      }
    }

    &--narrow {
      margin-right: 2em;
    }
  }

  &__reset-button,
  &__filter-button {
    background: none;
    border: none;
    margin: 0 0 16px 16px;
  }

  &__filters {
    margin-bottom: 16px;
    font-size: 0.9em;
    display: flex;
    padding-left: 1px;

    &--narrow {
      padding-right: 24px;
    }

    label {
      display: flex;
      align-items: center;
      margin-right: 32px;

      input[type="checkbox"] {
        margin-bottom: 0;
      }
    }
  }
}
