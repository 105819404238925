.Nav {
  max-width: calc(180px + 1.5em);

  &__wrapper {
    min-width: 180px;
    position: fixed;
    padding: 1.2em 1.5em;
    background: white;
    margin: 2em 1.5em;
    border: 1px solid #eee;
  }

  &__active {
    font-weight: bold;
  }

  &__logout {
    display: inline-block;
    margin-top: 1.3em;
    padding-top: 1em;
    border-top: 1px solid #ddd;
    width: 70%;
  }
}
