.Box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 98vh;

  &__wrapper {
    background-color: white;
    border: 1px solid #eee;
    padding: 2em;
    width: 90%;
    max-width: 600px;

    p {
      padding: 0 0 24px;
    }
  }
}
