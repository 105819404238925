body {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  color: #222;
  line-height: 32px;
}

h1,
h2,
h3 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-style: normal;
  font-weight: 500;
}

h1 {
  font-size: 1.4em;
  padding-bottom: 16px;
}

h2 {
  font-size: 1.2em;
  padding-bottom: 8px;
}

a {
  color: inherit;
  text-decoration: none;
}

label {
  display: block;
  input,
  select {
    display: block;
  }
}

strong {
  font-weight: 500;
}

input,
select,
button {
  border: 1px solid #eee;
  display: block;
  margin: 0 16px 16px 0;
  padding: 8px;
  font-size: 1em;
  font-family: inherit;
  font-weight: 300;
  color: inherit;
}

input:disabled {
  background-color: #f5f5f8;
}

button {
  cursor: pointer;
}

input[type="checkbox"] {
  display: inline;
  margin-right: 8px;
}
