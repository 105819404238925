@import "./styles/reset.scss";
@import "./styles/box-sizing.scss";
@import "./styles/base-styles.scss";
@import "./styles/typography.scss";

@import "./styles/Box.scss";

.App {
  &__wrapper {
    display: flex;
    align-items: flex-start;
  }

  &__loading {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    color: #999;
    font-size: 0.8em;
  }
}
