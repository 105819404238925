.ProjectKey {
  background-color: #f7f7f9;
  border-radius: 3px;
  margin-right: 0.5em;
  padding: 0.4em;
  display: inline-block;
  line-height: 1;
  font-weight: 300;
  border: 1px solid #f7f7f9;
  cursor: pointer;

  &__hash {
    color: #99a;
  }

  .CalendarEventsTable__wrapper tr:hover & {
    background-color: #f3f3f6;
  }

  .CalendarEventsTable__selected-project & {
    background-color: white !important;
    border: 1px solid #eee;
  }
}
