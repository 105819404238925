.SingleProject {
  display: flex;
  margin-left: -1px;
  border-left: 1px solid #eee;
  min-height: 100vh;
  box-shadow: 0 0 40px rgba(#000033, 0.05);
  font-variant-numeric: tabular-nums;

  &__wrapper {
    top: 0;
    max-height: 100vh;
    overflow-y: auto;
    background-color: white;
  }

  &__close-button {
    font-size: 1.4em;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    padding: 2em 2em 1.5em;
  }

  &__form-error {
    border: 1px solid #cc4444;
    padding: 4px 12px 2px;
    margin-bottom: 12px;
  }

  &__main,
  &__table-wrapper {
    font-size: 0.85em;
    padding: 2em 2em 0;
    border-top: 1px solid #eee;
  }
  &__main {
    background-color: #fdfdfd;
  }

  fieldset {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  label {
    width: calc(50% - 1em);
    font-weight: 500;
    font-size: 0.8rem;
  }

  label input {
    width: 100%;
    margin-bottom: 8px;
  }

  & &__checkbox-label {
    display: flex;
    align-items: center;
    padding: 10px 0 0;

    input {
      width: auto;
      margin: 0 8px 0 0;
    }
  }

  &__save-button-wrapper {
    display: flex;
    justify-content: flex-end;
    padding: 12px 0 0;

    button {
      margin: 0;
      padding-right: 32px;
      padding-left: 32px;
    }
  }

  &__inputs {
    padding: 0 0 32px;
  }

  &__events-table {
    width: 100%;
    margin: 0 0 3em;

    td {
      max-width: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    td + td ~ td {
      text-align: right;
    }
  }

  &__tr-sum {
    td {
      border-top: 2px solid #eee;
    }
  }
}
