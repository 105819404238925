.CalendarEventsTable {
  &__wrapper {
    min-width: 50%;
    padding: 2em 2em 2em 2em;

    &--has-selected {
      padding-right: 0;
    }

    table {
      font-size: 0.9em;
      background: white;
      width: 100%;
      border: 1px solid #eee;
      border-radius: 3px;
    }

    td,
    th {
      padding: 4px 6px;
      border-bottom: 1px solid #eee;
      text-align: left;
      & ~ td,
      & ~ th {
        padding: 4px 16px 2px;
        text-align: right;
      }
    }

    th {
      padding: 6px 12px 4px;
      font-weight: 500;
      border-bottom: 2px solid #eee;
    }

    tbody {
      tr {
        &:hover {
          background-color: #fafafd;
          cursor: pointer;
        }
      }
    }
  }

  &__table {
    &--loading {
      opacity: 0.2;
    }
  }

  &__selected-project {
    &,
    &:hover {
      background-color: #fafafd;
    }
  }

  &__selected-project &__project-key {
    background-color: #fff;
  }

  table tbody &__table-footer {
    cursor: default;
    border-top: 2px solid #eee;
    &:hover {
      background-color: inherit;
      cursor: default;
    }
  }
}
